<template>
  <div class="box">
    <van-empty
      class="custom-image"
      :image="noData"
      description="暂无企业数据"
      v-if="!info"
    />
    <div v-if="info">
      <!-- <div class="select">
        <van-dropdown-menu active-color="#0084FF">
          <van-dropdown-item
            v-model="company"
            :options="companyList"
            @change="changeCompany"
          />
        </van-dropdown-menu>
      </div> -->
      <div class="content_">
        <div class="_1_header">
          <div
            class="_1_item"
            :class="checkItem == index ? 'active' : ''"
            @click="iconCheck(index)"
            v-for="(item, index) in icons"
            :key="item.name"
          >
            <van-icon class="iconfont" :class="item.code" size="30" color="" />
            <div class="middle">{{ item.name }}</div>
            <div
              class="line"
              :class="checkItem == index ? 'activeLine' : ''"
            ></div>
          </div>
        </div>
        <!-- 基础信息 -->
        <div class="items" v-if="checkItem == 0">
          <van-cell title="公司名称" :value="info.name" size="large" />
          <van-cell title="法定代表人" :value="info.corporation" size="large" />
          <van-cell
            title="统一社会信用代码"
            :value="info.taxAccount"
            size="large"
          />
          <van-cell title="成立日期" :value="info.openingDate" size="large" />
          <van-cell title="注册地址" :value="info.regAddress" size="large" />
          <!-- <van-cell title="所属地区" value="内容" size="large" /> -->
          <van-cell
            title="纳税评级"
            :value="info.companyDataDTO.nsjb"
            size="large"
          />
          <van-cell title="经营范围" size="large">
            <div slot="">
              <div class="van-cell-text">{{ info.companyDataDTO.jyfw }}</div>
            </div>
          </van-cell>
        </div>
        <!-- { name: "股东/法人", code: "icon-gudong2" }, -->
        <div class="items_1" v-if="checkItem == 1">
          <div class="gudng">股东信息</div>
          <div class="tr">
            <div class="td">股东名称</div>
            <div class="td">认缴出资额</div>
            <div class="td">出资比例</div>
          </div>
          <div
            class="tr"
            v-for="item in info.holderInfoDTOs"
            :key="item.holderId"
          >
            <div class="td lt">{{ item.stockName }}</div>
            <div class="td md">{{ item.stockCapital }}</div>
            <div class="td rt">
              {{ item.stockProportion ? item.stockProportion * 100 : "-" }}%
            </div>
          </div>
          <div class="gudng">法人变更信息</div>
          <div class="item_">
            <van-cell
              title="最近一次法人变更时间"
              :value="info.legalChangeTime"
              size="large"
            />
          </div>
        </div>
        <!-- 经营信息 -->
        <div class="items" v-if="checkItem == 2">
          <van-cell
            title="近24个月是否有经营异常"
            :value="info.companyDataDTO.jyyc == 0 ? '否' : '有'"
            size="large"
          />
          <van-cell
            title="近24个月是否有涉诉记录"
            :value="info.companyDataDTO.flss24 == 0 ? '否' : '有'"
            size="large"
          />
          <van-cell
            title="是否为优质供应商"
            :value="info.webankNum > 0 ? '是' : '否'"
            size="large"
          />
          <van-cell
            title="近12个月开票企业数"
            :value="info.companyDataDTO.kpgss12"
            size="large"
          />
        </div>
        <!-- 开票信息 -->
        <div class="items" v-if="checkItem == 3">
          <van-cell
            title="近3个月断开票次数"
            :value="info.companyDataDTO.ljdkpsj3"
            size="large"
          />
          <van-cell
            title="近12个月断开票次数"
            :value="info.companyDataDTO.ljdkpsj12"
            size="large"
          />
          <van-cell
            title="近3个月开票总额"
            :value="info.companyDataDTO.kpje3 + '元'"
            size="large"
          />
          <van-cell
            title="近12个月开票总额"
            :value="info.companyDataDTO.kpje12 + '元'"
            size="large"
          />
          <van-cell
            title="近24个月开票总额"
            :value="info.companyDataDTO.kpje24 + '元'"
            size="large"
          />
          <van-cell
            title="近36个月首次开票距今月份"
            :value="info.companyDataDTO.kpsj"
            size="large"
          />
        </div>
        <!-- 纳税信息 -->
        <div class="items" v-if="checkItem == 4">
          <van-cell
            title="近12个月断缴税次数"
            :value="info.companyDataDTO.lsbcs12"
            size="large"
          />
          <van-cell
            title="近12个月滞纳金次数"
            :value="info.companyDataDTO.znjcs12"
            size="large"
          />
          <van-cell
            title="近3个月纳税额度"
            :value="info.companyDataDTO.zzsds3 + '元'"
            size="large"
          />
          <van-cell
            title="近12个月纳税额度"
            :value="info.companyDataDTO.zzsds12 + '元'"
            size="large"
          />
          <van-cell
            title="近24个月纳税额度"
            :value="info.companyDataDTO.zzsds24 + '元'"
            size="large"
          />
          <van-cell
            title="近36个月首次纳税距今月份"
            :value="info.companyDataDTO.nssj"
            size="large"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";

Vue.use(Toast);
import url from "@/service/url-config";
export default {
  data() {
    return {
      noData: require("@/img/qy.png"),
      company: "",
      companyList: [],
      info: {
        companyDataDTO: {},
      },
      checkItem: 0,
      icons: [
        {
          name: "基础信息",
          code: "icon-a-bianzu3",
        },
        {
          name: "股东/法人",
          code: "icon-gudong",
        },
        {
          name: "经营信息",
          code: "icon-jingying",
        },
        {
          name: "开票信息",
          code: "icon-bianzu16",
        },
        {
          name: "纳税信息",
          code: "icon-nasui",
        },
        // { name: "授权历史", code: "icon-bianzu16" }
      ],
    };
  },
  created() {
    // this.getCompanyList();
    this.getCompanyDetail(this.$route.query.companyId);
  },
  methods: {
    //获取企业列表
    // getCompanyList() {
    //   this.$http.get(url.companyList).then((res) => {
    //     res.data.forEach((v) => {
    //       this.companyList.push({
    //         text: v.name,
    //         value: v.companyId,
    //       });
    //     });
    //     if (this.$route.query.companyId) {
    //       this.company = Number(this.$route.query.companyId);
    //     } else {
    //       this.company = this.companyList[0].value;
    //     }
    //     this.getCompanyDetail(this.company);
    //   });
    // },
    getCompanyDetail(companyId) {
      this.$http
        .get(url.getCompanyDetail + `?companyId=${companyId}`)
        .then((res) => {
          this.info = res.data;
        });
    },
    iconCheck(val) {
      this.checkItem = val;
    },
    //切换企业
    changeCompany(val) {
      this.getCompanyDetail(val);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f9f9f9;
  min-height: 100vh;
  padding-top: 46px;
  font-size: 12px;

  .van-cell.van-cell--clickable.van-dropdown-item__option.van-dropdown-item__option--active {
    .van-cell {
      justify-content: space-between !important;
    }
    .van-cell__value {
      width: 20px !important;
    }
  }

  .select {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    /deep/.van-cell {
      justify-content: space-between !important;
    }

    /deep/.van-cell__value {
      width: 20px !important;
    }
  }

  .content_ {
    background: #f9f9f9;
    height: calc(100vh - 76px);
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 15px 10px 65px;
    box-shadow: 0px -2px 4px 0px #eeeeee;
  }

  ._1_header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    height: 78px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 25px;
  }

  .van-icon {
    color: #c0c0c0;
  }

  .active {
    color: #0084ff;
  }

  .activeLine {
    border: 1px solid #0084ff;
  }

  .active /deep/ .van-icon {
    color: #0084ff;
  }

  .middle {
    margin: 5px 0;
  }

  .line {
    width: 34px;
    border: 1px solid #ffffff;
    margin: 0 auto;
  }

  .content_ {
    background: #ffffff;
    color: #6f6f6f;
  }

  .items_1 {
    margin-top: 25px;
    padding: 15px 20px;
  }

  .gudng {
    line-height: 22px;
    font-size: 15px;
    color: #333;
    font-weight: bold;
    padding: 5px 0;
    // border-bottom: 1px solid #ededed;
  }

  .tr {
    display: flex;
    justify-content: space-between;
    text-align: center;
    line-height: 40px;
    color: #999999;
    // border-bottom: 1px solid #ededed;
    border-color: #ededed;
  }

  .td {
    flex: 1;
    border-bottom: 1px solid #ededed;
  }

  .tr .lt {
    color: #0084ff;
  }

  .tr .md {
    color: #333333;
  }

  .tr .rt {
    color: #ff4338;
  }

  .item_ {
    width: 350px;
    position: relative;
    left: -25px;
  }

  .van-cell__title {
    color: #666666;
    font-size: 12px;
  }

  .van-cell__value {
    color: #333333;
    font-size: 12px;
  }
}
</style>